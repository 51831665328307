import React, {useState, useEffect } from 'react'
import {Link} from "react-router-dom"
import './Pending_doctor.css';
import FadeLoader from "react-spinners/FadeLoader";
import Connection from "../../connection";
import { doc_profile } from '../../Image_connection';

export default function Doctor_list() {
    const [products, setProducts]= useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        window.scrollTo(0, 0);

        getPending_doctor()
    },[])

    const getPending_doctor = async()=>{
        let result = await fetch(Connection + "rest_apis.php?action=pending_doctor",{})
        result = await result.json()
        const responseArray = result.response;
  
        if (responseArray != "fail") {
           console.log("All Pending Doctor => ", responseArray)
           setProducts(responseArray)
           setIsLoading(false);
        }
        else {
            setProducts([])
            setIsLoading(false);
        }
    }

   return (
    <React.Fragment>
        <div className='main_view'>
            <h2>PENDING DOCTOR</h2>
 
        {isLoading ? (
            <div className='loader_doctor_list'>
                <FadeLoader
                     color={"#19c8de"}
                     loading={isLoading}
                     size={30}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                />
                <p>Loading ...</p>
            </div>
        ) 
        : 
        (
            <>
            {products != '' ?
            <>
            <div className='all_kard'>
              {products.map((row) => (
                <div className='displayblock'>
                    <div className='doctor_cad_2'>
                        {row.profile == null ?
                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                        :
                            <img src={doc_profile + row.profile} title="Profile Image" alt=""/>
                        }

                        <div className='list_in_cad_2'>
                             <h6>{row.name}</h6>
                             
                             <h5>{row.category}</h5>
                             <h5>{row.email}</h5>
                             <h4>{row.mobile_number}</h4>
                            
                        </div>
                        <span className='book_appo'>
                          <Link to={"/Doctor_Details/"+row.id}>Doctor Profile</Link>
                        </span>
                    </div>
                </div>
                ))}
            </div>
            </>
            :
            <div style={{width:'100%',justifyContent:'center',alignItems:'center',height:'74vh',display:'flex'}}>
               <p>No Record Found</p>
            </div>
            }
            </>
        )}

        </div>
        </React.Fragment>
   );
}