import React, { useState, useEffect } from 'react'
import './Percentage_update.css';
import Connection from "../../connection";
import PulseLoader from "react-spinners/PulseLoader";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

export default function Percentage_update() {
    const navigat = useNavigate()

    const [error, setError] = useState(false);
    const [percentage, setPercentage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalsuccess, setModalsuccess] = useState(false);

    const [getupdate, setGetupdate] = useState('');

    useEffect(() => {
        Get_Percentage()
    }, [])


    const Get_Percentage = async () => {
        let uploaddata = new FormData();

        uploaddata.append("id", 1);

        let api = Connection + "rest_apis.php?action=get_percentage";
        console.log("pass => ", api);

        fetch(api, {
            method: "POST",
            headers: {
            },
            body: uploaddata,
        })
            .then((response) => response.json())
            .then((response) => {

                let record = response.response;

                if (record != "fail") {
                    const percentage = record[0].percentage
                    setGetupdate(record)
                    setPercentage(percentage)
                }
                else {
                    setGetupdate()
                    setPercentage()
                    alert('Something went wrong')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const Exitupdate = async () => {
        let uploaddata = new FormData();

        if (percentage == '') {
            alert("Please enter Percentage.")
        }
        else {
            setIsLoading(true);

            uploaddata.append("id", 1);
            uploaddata.append("percentage", percentage);

            let api = Connection + "rest_apis.php?action=Exit_update";
            console.log("pass => ", api);

            fetch(api, {
                method: "POST",
                headers: {
                },
                body: uploaddata,
            })
                .then((response) => response.json())
                .then((response) => {

                    let record = response.response;

                    if (record === "success") {
                        setPercentage()
                        setIsLoading(false)
                        setModalsuccess(true)
                    }
                    else {
                        setPercentage()
                        setIsLoading(false)

                        alert('Something went wrong')
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }


    const Succeess = async (event) => {
        setModalsuccess(false)
        navigat('/')
    }

    const handleInput = (e) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        const twoDigitValue = numericValue.slice(0, 2);
        setPercentage(twoDigitValue);
    };



    return (
        <div className='table_review'>

            <div className='responsive_review'>
                <div className='head_card'>
                    <h2>Update Percentage</h2>
                </div>
                <hr></hr>


                <div className='Update_Card'>

                    <div className="update_input">
                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>Update</label>
                            </div>

                            <input type="text" onInput={handleInput} className="update_percentage" id="update_percen" placeholder="Percentage" value={percentage} pattern="[0-9]*" maxLength="2" />
                        </div>
                    </div>

                    <div class="Updatebuttun">
                        {isLoading ?
                            <button class="submit" id='loader' type="reset">
                                <PulseLoader
                                    color={"white"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :

                            <button onClick={Exitupdate} class="submit" id='submite' type="submit">Update</button>
                        }
                    </div>
                </div>
            </div>


            <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
                <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
                    Successfully
                </ModalHeader>

                <ModalBody>
                    <div className='succeess_modal'>
                        <h4>You have successfully Update Percentage</h4>
                        <button onClick={Succeess}>Done</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}