import React, { useState, useEffect } from 'react'
import './Transactions.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Transactions_Paginations from './Transactions_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";

export default function Transactions() {

  const [transactions, setTransactions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = transactions.slice(firstPostIndex, lastPostIndex)

  const params = useParams();

  useEffect(() => {
    get_Transactions()
  }, [])
  const get_Transactions = async () => {
    let result = await fetch(Connection + "rest_apis.php?action=get_wallet", {})
    result = await result.json()
    const responseArray = result.response;

    if (responseArray != "fail") {
      console.log("All User => ", responseArray)
      setTransactions(responseArray)
    }
    else {
      setTransactions([])
    }
  }



  return (
    <div className='Transactions_main_table'>

      <div className='respons_transtion_card'>
        <div className='head_card'>
          <h2>Transactions</h2>
        </div>
        <hr></hr>

        {currentPosts != '' ?
          <div className='transcation_table'>
            <table className='trans_tble_tag'>
              <tr>
                <th>Appointment Id</th>
                <th>User Id</th>
                <th>Doctor Id</th>
                <th>Doctor Earning</th>
                <th>Company's Earning</th>
                <th>Payment Method</th>
                <th>Date</th>
              </tr>


              {currentPosts.map((row) => (
                <tr>
                  <td>{row.appointment_id}</td>
                  <td>{row.user_id}</td>
                  <td>{row.doctor_id}</td>
                  <td>${row.d_earnings}</td>
                  <td>${row.profit}</td>
                  <td>{row.payment_method}</td>
                  <td>{row.date}</td>
                </tr>
              ))}
            </table>
          </div>
          :
          <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
            <p>No Record Found</p>
          </div>
        }
      </div>

      <Transactions_Paginations totalPosts={transactions.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

    </div>
  );
}