import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import './Appointment.css';
import Appoi_Paginations from './Appoi_Paginations';
import Connection from "../../connection";
import { user_profile, doc_profile } from '../../Image_connection';


export default function Appointment() {

   const [products, setProducts] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(10);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = products.slice(firstPostIndex, lastPostIndex)

   useEffect(() => {
      window.scrollTo(0, 0);

      getProducts()
   }, [])

   const getProducts = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=admin_get_apointment", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray != "fail") {
         console.log("All Doctor => ", responseArray)
         setProducts(responseArray)
      }
      else {
         setProducts([])
      }
   }

   return (
      <div className='appoint_table_vie'>
         <div className='back_table'>
            <h2>APPOINTMENT</h2>
            <hr></hr>

            {currentPosts != '' ?

               <table className='table'>
                  <tr>
                     <th>ID</th>
                     <th>Doctor</th>
                     <th>Patient</th>
                     <th>Category</th>
                     <th>Date</th>
                     <th>Time</th>
                     <th>Fee</th>
                     <th>Status</th>
                  </tr>

                  {currentPosts.map((row) => (
                     <tr>
                        <td>{row.id}</td>

                        <td>
                           {row.doctor_profile == null ?
                              <div>
                                 <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                              </div>
                              :
                              <div>
                                 <img src={doc_profile + row.doctor_profile} title="Profile Image" alt="" />
                              </div>
                           }
                           <p>{row.doctor_name}</p>
                        </td>

                        <td>
                           {row.user_profile == null ?
                              <div>
                                 <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                              </div>
                              :
                              <div>
                                 <img src={user_profile + row.user_profile} title="Profile Image" alt="" />
                              </div>
                           }
                           <p>{row.user_name}</p>
                        </td>

                        <td>{row.category}</td>
                        <td>{row.date}</td>
                        <td>{row.time}</td>
                        <td>{row.fee}</td>
                        <td>
                           <div className='status'>
                              {row.status}
                           </div>
                        </td>
                     </tr>
                  ))}
               </table>
               :
               <div style={{ textAlign: 'center', marginTop: 10,marginBottom:10 }}>
                  <p>No Record Found</p>
               </div>
            }
         </div>

         <Appoi_Paginations totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      </div>
   );
}