import React, { useState, useEffect, useMemo, useContext } from 'react'
import './Login.css';
// import logo from '../../logo.svg';
import t4 from '../../assets/t4.png';
import Connection from "../../connection";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import PulseLoader from "react-spinners/PulseLoader";
import { GlobalInfo } from "../../App"

function Login(props) {
  const { loginenter } = useContext(GlobalInfo)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigat = useNavigate()

  const Login = async (event) => {
    event.preventDefault();

    if (!email) {
      alert('Please enter your email.');
    }
    else if (!password) {
      alert('Please enter your password.');
    }
    else {
      setIsLoading(true);

      console.warn(email, password)

      let uploaddata = new FormData();

      uploaddata.append("email", email);
      uploaddata.append("password", password);

      let api = Connection + "rest_apis.php?action=login_admin";
      console.log("pass => ", api);

      fetch(api, {
        method: "POST",
        headers: {
        },
        body: uploaddata,
      })
        .then((response) => response.json())
        .then((response) => {

          let record = response.response;
          const firstRecord = record[0]
          console.log("record => ", firstRecord);

          if (record === "fail") {
            setIsLoading(false);
            alert('Invalid Email or Password.')
          }
          else {
            setIsLoading(false);
            localStorage.setItem("user", JSON.stringify(firstRecord))
            loginenter()
            //  window.location.href = '/'
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }


  return (
    <div class="main_Login_div">
      <div className='both_handle'>
        <img src={t4}></img>

        <div class="box">
          <h3>Welcome Back</h3>
          <h5>Enter your credentials to access your account.</h5>

          <form action="">
            <div class="input_box">
              <input type="text" name="Email" id="name" autocomplete="off" placeholder="Email" required onChange={(e) => setEmail(e.target.value)} />
              {/* <label for="Username">Email</label> */}
            </div>

            <div class="input_box">
              <input type="password" name="password" id="name" placeholder="Password" autocomplete="off" required onChange={(e) => setPassword(e.target.value)} />
              {/* <label for="Password">Password</label> */}
            </div>

            {isLoading ?
              <button id='delete_loader'>
                <PulseLoader
                  color={"white"}
                  loading={isLoading}
                  //  size={5}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
              :
              <Link className="link" to={"/"} onClick={Login} value="Login" >Log In</Link>
            }

          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;