import React, { useState, useEffect } from 'react'
import './Patient_list.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Pagination from './Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import { user_profile } from '../../Image_connection';

export default function Patient_list() {

   const [modalDelete, setModalDelete] = useState(false);

   const [products, setProducts] = useState([]);
   const [oldRecord, setOldRecord] = useState([]);


   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(10);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = products.slice(firstPostIndex, lastPostIndex)

   const [user_id, setUser_id] = React.useState("");

   const [patLoading, setPatLoading] = useState(false);

   const params = useParams();

   useEffect(() => {
      window.scrollTo(0, 0);

      getProducts()
   }, [])

   const getProducts = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=admin_user_al", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray != "fail") {
         console.log("All User => ", responseArray)
         setProducts(responseArray)
         setOldRecord(responseArray)
      }
      else {
         setProducts([])
         setOldRecord([])
      }
   }


   const open_delete_modal = (id) => {
      setModalDelete(true)
      setUser_id(id)
   }


   const Search_Doctor = async (event) => {
      event.preventDefault();

      const name = event.target.value
      console.log("search", name)

      if (name == "") {
         setProducts(oldRecord)
      }
      else {
         const results = oldRecord.filter(item =>
            item.name.toLowerCase().includes(name.toLowerCase())
         );

         setProducts(results)
      }
   }



   const Delete_patient = async (event) => {
      event.preventDefault();

      setPatLoading(true);

      let uploaddata = new FormData();

      uploaddata.append("user_id", user_id);

      let api = Connection + "rest_apis.php?action=delete_Account";
      console.log("pass => ", api);

      fetch(api, {
         method: "POST",
         headers: {
         },
         body: uploaddata,
      })
         .then((response) => response.json())
         .then((response) => {

            let record = response.response;
            const firstRecord = record[0]
            console.log("record => ", record);

            if (record === "fail") {
               setModalDelete(!modalDelete)
               setPatLoading(false);
               alert('Something went wrong')
            }
            else {
               setModalDelete(!modalDelete)
               setPatLoading(false);
               getProducts()
            }
         })
         .catch((error) => {
            console.error(error);
         });
   }


   return (
      <div className='table_view'>

         <div className='responsive_card'>
            <div className='head_card'>
               <h2>PATIENT</h2>
               <div className='search_patient'>
                  <h6>Search</h6>
                  <input type="search" className="search" id="Search" placeholder="Search" onChange={Search_Doctor} />
               </div>
            </div>
            <hr></hr>

            {currentPosts != '' ?
               <div className='table_responsive'>
                  <table className='table_tag'>
                     <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Action</th>
                     </tr>


                     {currentPosts.map((row) => (
                        <tr>
                           <td>{row.id}</td>
                           <td>
                              {row.profile == null ?
                                 <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                 :
                                 <img src={user_profile + row.profile} title="Profile Image" alt="" />
                              }
                           </td>
                           <td>{row.name}</td>
                           <td>{row.email}</td>
                           <td>{row.mobile_number}</td>
                           <td>
                              <span className='aution_btn'>
                                 <button onClick={() => open_delete_modal(row.id)}>Remove</button>
                              </span>
                           </td>
                        </tr>
                     ))}
                  </table>
               </div>
               :
               <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                  <p>No Record Found</p>
               </div>
            }
         </div>

         <Pagination totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />


         <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
            <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
               DELETE PATIENT
            </ModalHeader>

            <ModalBody>
               <div className='delete_modal'>
                  <h4>Are you sure you want to delete this patient?</h4>
                  {patLoading ?
                     <button id='delete_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={Delete_patient}>Delete</button>
                  }
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}