import React, { useState, useEffect, } from 'react'
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
import './Doctor_Details.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import { user_profile, doc_profile } from '../../Image_connection';
import ReactStars from "react-rating-stars-component";

export default function Doctor_Details() {
    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);
    const [text3, setText3] = React.useState(false);
    const [text5, setText5] = React.useState(false);

    const [modalDelete, setModalDelete] = useState(false);
    const [modalapproved, setModalapproved] = useState(false);
    const [modal_disapproved, setModal_disapproved] = useState(false);

    const [name, setName] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [consult_now, setConsult_now] = React.useState("");

    const [mobile_number, setMobile_number] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [address, setAddress] = React.useState("");

    const [favourite, setFavourite] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [degree, setDegree] = React.useState("");
    const [about, setAbout] = React.useState("");
    const [license_number, setLicense_number] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [license_image, setIicense_image] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [status, setStatus] = React.useState("");

    const [patLoading, setPatLoading] = useState(false);
    const [reviews, setReviews] = useState(false);

    const params = useParams();
    const navigate = useNavigate();


    // Change Btn
    const openText1 = (event) => {
        event.preventDefault();
        setText1(true);
        setText2(false);
        setText3(false);
        setText5(false);
    };
    const openText2 = (event) => {
        event.preventDefault();
        setText2(true);
        setText1(false);
        setText3(false);
        setText5(false);
    };
    const openText3 = (event) => {
        event.preventDefault();
        setText3(true);
        setText1(false);
        setText2(false);
        setText5(false);
    };
    const openText5 = (event) => {
        event.preventDefault();
        setText5(true);
        setText1(false);
        setText2(false);
        setText3(false);
    };



    useEffect(() => {
        window.scrollTo(0, 0);

        getProducts()
        getReview()
    }, [])


    const getProducts = async () => {
        let uploaddata = new FormData();

        uploaddata.append("id", params.id);

        let api = Connection + "rest_apis.php?action=one_doctor";
        console.log("pass => ", api);

        fetch(api, {
            method: "POST",
            headers: {
            },
            body: uploaddata,
        })
            .then((response) => response.json())
            .then((response) => {

                let record = response.response;
                const firstRecord = record[0]
                console.log("record => ", firstRecord);

                if (record === "fail") { }
                else {
                    setName(firstRecord.name)
                    setCategory(firstRecord.category)
                    setConsult_now(firstRecord.consult_now)

                    setMobile_number(firstRecord.mobile_number)
                    setEmail(firstRecord.email)
                    setAddress(firstRecord.address)
                    setFavourite(firstRecord.favourite)
                    setExperience(firstRecord.experience)
                    setDegree(firstRecord.degree)
                    setAbout(firstRecord.about)
                    setLicense_number(firstRecord.license_number)
                    setProfile(firstRecord.profile)
                    setIicense_image(firstRecord.c_images)
                    setFee(firstRecord.fee)
                    setStatus(firstRecord.status)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const getReview = async () => {
        let uploaddata = new FormData();

        uploaddata.append('doctor_id', params.id);

        let api = Connection + 'rest_apis.php?action=Get_Reviews_with_user_all';

        fetch(api, {
            method: 'POST',
            headers: {
            },
            body: uploaddata,
        })
            .then(response => response.json())
            .then(response => {

                let record = response.response;

                if (record != 'fail') {
                    setReviews(record)
                }
                else { }
            })
            .catch((error) => {
                console.error(error);
            });
    }





    const Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let uploaddata = new FormData();

        uploaddata.append('id', params.id);

        let api = Connection + 'rest_apis.php?action=approve_doctor';

        fetch(api, {
            method: 'POST',
            headers: {
            },
            body: uploaddata,
        })
            .then(response => response.json())
            .then(response => {

                let record = response.response;

                if (record != 'fail') {
                    setPatLoading(false);
                    setModalapproved(false)
                    navigate('/Pending_doctor')
                }
                else {
                    setPatLoading(false);
                    setModalapproved(false)
                    alert('Something went wrong')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const Dis_Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let uploaddata = new FormData();

        uploaddata.append('id', params.id);

        let api = Connection + 'rest_apis.php?action=disapprove_doctor';

        fetch(api, {
            method: 'POST',
            headers: {
            },
            body: uploaddata,
        })
            .then(response => response.json())
            .then(response => {

                let record = response.response;

                if (record != 'fail') {
                    setPatLoading(false);
                    setModal_disapproved(false)
                    navigate('/Doctor_list')
                }
                else {
                    setPatLoading(false);
                    setModal_disapproved(false)
                    alert('Something went wrong')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }




    const Delete_patient = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let uploaddata = new FormData();

        uploaddata.append("user_id", params.id);

        let api = Connection + "rest_apis.php?action=delete_Account";
        console.log("pass => ", api);

        fetch(api, {
            method: "POST",
            headers: {
            },
            body: uploaddata,
        })
            .then((response) => response.json())
            .then((response) => {

                let record = response.response;

                if (record === "fail") {
                    setPatLoading(false);
                    setModalDelete(false)
                    alert('Something went wrong')
                }
                else {
                    setPatLoading(false);
                    setModalDelete(false)
                    navigate('/Doctor_list')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }




    return (
        <div className='Doctor_head'>
            <h2>DOCTOR PROFILE</h2>

            <div className='All_in_this'>

                <div className='first_column'>
                    <div className='Profile_card'>
                        <div className='img-wrapper'>
                            {profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                :
                                <div className='profileorbtn'>
                                    <img src={doc_profile + profile} title="Profile Image" alt="" />
                                </div>
                            }
                        </div>

                        <div className='profile_detail'>
                            <div className='viewtorowfrnewbtn'>
                                <h2>{name}</h2>
                            </div>
                            <div className='star'>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <span>(38)</span>
                            </div>
                            <h3>{category}</h3>

                            <div className='just_row'>
                                <i class="bi bi-telephone"></i>
                                <h4>{mobile_number}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-envelope-at"></i>
                                <h4>{email}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-currency-dollar"></i>
                                <h4>{fee}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='chanebtn_card'>
                        <div className={text1 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText1}>
                            <a href='#'>About Me</a>
                        </div>

                        <div className={text2 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText2}>
                            <a href='#'>Reviews</a>
                        </div>

                        <div className={text3 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText3}>
                            <a href='#'>Approved</a>
                        </div>

                        <div className={text5 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText5}>
                            <a href='#'>Delete</a>
                        </div>
                    </div>


                    {text1 == true ?
                        <div className='About_in_btn'>
                            <h2>ABOUT</h2>
                            <hr></hr>

                            <div className='numer_licen'>
                                <h3>License Number</h3>
                                <h4>{license_number}</h4>
                                {/* <hr></hr> */}
                            </div>

                            {/* <div className='img_licen'>
                                <h3>License Image</h3>
                                {license_image == null ?
                                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                    :
                                    <img src={"http://afrimed-app.com/afrimed/Certificates_Image/" + license_image} title="license Image" alt="" />
                                }
                            </div> */}
                        </div>
                        :
                        <div></div>
                    }


                    {text2 == true ?
                        <div className='Review_Card'>
                            <h2>Recent Review</h2>

                            {reviews == "" ?
                                <div className='Noreview'>
                                    <h4>No Review Available</h4>
                                </div>
                                :
                                <>
                                    {reviews.map((row) => (
                                        <div className='review_all'>
                                            {row.profile == null ?
                                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                                :
                                                <img src={user_profile + row.profile} title="license Image" alt="" />
                                            }

                                            <div className='review_details'>
                                                <h3>{row.name}</h3>

                                                <p>{row.comment}</p>

                                                <div className='bottom_deatil_review'>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>({row.total_review})</h3>
                                                        <ReactStars
                                                            count={5}
                                                            value={row.rating}
                                                            size={24}
                                                            edit={false}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                        </div>
                        :
                        <div></div>
                    }



                    {text3 == true ?
                        <div className='Approv_Diss_Card'>
                            <h2>For Approval</h2>
                            <hr></hr>

                            <div style={{ display: 'flex', alignSelf: 'center', gap: 20, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <span className='two_buttun'>
                                    {status == 'approved' ?
                                        <button onClick={() => { setModal_disapproved(true) }}>Disapproved</button>
                                        :
                                        <button onClick={() => { setModalapproved(true) }}>Approved</button>
                                    }
                                </span>
                            </div>

                        </div>
                        :
                        <div></div>
                    }


                    {text5 == true ?
                        <div className='Delete_Card'>
                            <h2>Delete Account</h2>
                            <hr></hr>

                            <h4>Are you sure, You want to delete this doctor?</h4>

                            <span className='delete_buttun'>
                                <button onClick={() => { setModalDelete(true) }}>Delete</button>
                            </span>
                        </div>
                        :
                        <div></div>
                    }
                </div>

                <div className='second_column'>
                    <div className='About_card'>
                        <div className='about_head'>
                            <h2>About Me</h2>
                        </div>

                        <hr></hr>

                        <div className='about_data'>
                            <p>{about}</p>
                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Experience</h3>
                                <h4>{experience} years</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Degree</h3>
                                <h4>{degree}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Designation</h3>
                                <h4>J.Professer</h4>
                            </div>

                            <hr></hr>

                            <div className='about_last_row'>
                                <div className='about_last_section'>
                                    <h3>37</h3>
                                    <h4>PATIENT</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>61</h3>
                                    <h4>REVIEW</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>{fee}</h3>
                                    <h4>FEE</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Address_Card'>
                        <div className='address_head'>
                            <h2>Address</h2>
                        </div>

                        <hr></hr>

                        <div className='address_paragraph'>
                            <p>{address}</p>
                        </div>
                    </div>
                </div>

            </div>


            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE PATIENT
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this patient?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_patient}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modalapproved} toggle={() => setModalapproved(!modalapproved)}>
                <ModalHeader toggle={() => setModalapproved(!modalapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to approved this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Approved_doctor}>Approved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modal_disapproved} toggle={() => setModal_disapproved(!modal_disapproved)}>
                <ModalHeader toggle={() => setModal_disapproved(!modal_disapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to disapproved this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Dis_Approved_doctor}>Disapproved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>

        </div>
    );
}