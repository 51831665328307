import "./sidebar.css";
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { GlobalInfo } from "../../App"
import { Link, useNavigate } from "react-router-dom";

import HomeIcon from '@mui/icons-material/Home';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import MedicationIcon from '@mui/icons-material/Medication';
import AccessibleIcon from '@mui/icons-material/Accessible';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LogoutIcon from '@mui/icons-material/Logout';

export default function Sidebar() {
   const { loginenter } = useContext(GlobalInfo)

   const navigat = useNavigate()

   const logout = async (e) => {
      console.log("logout")
      localStorage.clear();
      loginenter()
      // window.location.href = '/'
   }

   // useEffect(() => {
   //    const timer = setTimeout(() => {
   //         logout()
   //         loginenter()
   //    }, 3000);

   //    // Clear the timer when the component unmounts or the user manually logs out
   //    return () => {
   //      clearTimeout(timer);
   //    }
   //  }, [logout]);

   return (
      <div className="sidebar">
         <div className="sidebarWrapper">

            <ul className="sidebarList">
               <Link className="link">
                  <li className="logotop">DIASPORAPHA</li>
               </Link>
            </ul>

            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Dashboard</h3>

               <ul className="sidebarList">
                  <Link to="/" className="link">
                     <li className="sidebarListItem active"><HomeIcon className="sidebarIcon" />Dashboard</li>
                  </Link>

                  {/* <Link to="/Percentage_update" className="link">
                     <li className="sidebarListItem active"><EmojiSymbolsIcon className="sidebarIcon" />Percentage</li>
                  </Link> */}
               </ul>
            </div>


            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Patient</h3>

               <ul className="sidebarList">
                  <Link to="/Patient_list" className="link">
                     <li className="sidebarListItem active"><AccessibleIcon className="sidebarIcon" />Patient List</li>
                  </Link>
               </ul>
            </div>


            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Doctor</h3>

               <ul className="sidebarList">
                  <Link to="/Doctor_list" className="link">
                     <li className="sidebarListItem"><MedicationIcon className="sidebarIcon" />Doctor List</li>
                  </Link>

                  <Link to="/Pending_doctor" className="link">
                     <li className="sidebarListItem"><MedicationIcon className="sidebarIcon" />Pending Doctor</li>
                  </Link>
               </ul>
            </div>


            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Appointment</h3>

               <ul className="sidebarList">
                  <Link to="/Appointment" className="link">
                     <li className="sidebarListItem active"><CalendarMonthIcon className="sidebarIcon" />Appointment</li>
                  </Link>
               </ul>
            </div>

            {/* <div className="sidebarMenu">
               <h3 className="sidebarTitle">City</h3>

               <ul className="sidebarList">
                  <Link to="/City" className="link">
                     <li className="sidebarListItem active"><LocationCityIcon className="sidebarIcon" />City</li>
                  </Link>

                  <Link to="/Delet_City" className="link">
                     <li className="sidebarListItem active"><LocationCityIcon className="sidebarIcon" />Edit City</li>
                  </Link>
               </ul>
            </div> */}


            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Wallet</h3>

               <ul className="sidebarList">
                  <Link to="/Transactions" className="link">
                     <li className="sidebarListItem"><AccountBalanceWalletIcon className="sidebarIcon" />Transactions</li>
                  </Link>
               </ul>
            </div>


            <div className="sidebarMenu">
               <h3 className="sidebarTitle">Log Out</h3>

               <ul className="sidebarList">
                  <Link onClick={logout} className="link">
                     <li className="sidebarListItem active"><LogoutIcon className="sidebarIcon" />logout</li>
                  </Link>
               </ul>
            </div>

         </div>
      </div>
   );
}
