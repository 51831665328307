import React, { useState, useEffect, createContext } from 'react'
import Connection from "./connection";
import './App.css';

import Sidebar from './MyComponent/sidebar/Sidebar';
import Topbar from './MyComponent/topbar/Topbar';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Percentage_update from './pages/Percentage_update/Percentage_update';
import Appointment from './pages/Appointment/Appointment';
import City from './pages/City/City';
import Delet_City from './pages/Delet_City/Delet_City';
import Patient_list from './pages/Patient_list/Patient_list';
import Doctor_list from './pages/Doctor_list/Doctor_list';
import Doctor_Details from './pages/Doctor_Details/Doctor_Details';
import Pending_doctor from './pages/Pending_doctor/Pending_doctor';
import Transactions from './pages/Transactions/Transactions';


import Privatecom from './pages/Privatecom'

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

export const GlobalInfo = createContext()

function App() {

   const [auth, setAuth] = useState('');

   const [appointment, setAppointment] = useState([]);
   const [doctor, setDoctor] = useState([]);
   const [patient, setPatient] = useState([]);

   const [app_count, setApp_count] = React.useState(0);
   const [user_count, setUser_count] = React.useState(0);
   const [doctor_count, setDoctor_count] = React.useState(0);
   const [pen_doctor_count, setPen_doctor_count] = React.useState(0);

   const loginenter = () => {
      const auth = localStorage.getItem("user");
      setAuth(auth)
   }


   useEffect(() => {
      console.log("auth", auth)
      loginenter()
      
      get_all_counting()
      get_appointment()
      get_doctor()
      get_user()
   }, [])


   const get_all_counting = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=get_count", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray) {
         console.log("get_count => ", responseArray)

         const app_count = responseArray[0].app_count
         const user_count = responseArray[0].user_count
         const doctor_count = responseArray[0].doctor_count
         const pen_doctor_count = responseArray[0].pen_doctor_count
   
         setApp_count(app_count)
         setUser_count(user_count)
         setDoctor_count(doctor_count)
         setPen_doctor_count(pen_doctor_count)
      }
   }

   const get_appointment = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=das_get_appointment", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray) {
         console.log("All Das Get Appointment => ", responseArray)
         setAppointment(responseArray)
      }
   }

   const get_doctor = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=das_get_doctor", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray) {
         console.log("All Doctor => ", responseArray)
         setDoctor(responseArray)
      }
   }

   const get_user = async () => {
      let result = await fetch(Connection + "rest_apis.php?action=das_get_user", {})
      result = await result.json()
      const responseArray = result.response;

      if (responseArray) {
         console.log("All User => ", responseArray)
         setPatient(responseArray)
      }
   }

   return (

      <GlobalInfo.Provider value={{ loginenter: loginenter, appointment: appointment, doctor: doctor, patient: patient, app_count: app_count, user_count: user_count, doctor_count: doctor_count, pen_doctor_count: pen_doctor_count }}>
         <BrowserRouter>
            {!auth ? (
               <Routes>
                  <Route path='/login' element={<Login />}></Route>
                  <Route path='/*' element={<Login />}></Route>
               </Routes>
            ) : (
               <div className='app'>
                  <Sidebar />


                  <div className='topbar_page'>
                     <Topbar />


                     <Routes>
                        <Route element={<Privatecom />} >

                           <Route path='/' element={<Home />}></Route>
                           <Route path='/Percentage_update' element={<Percentage_update />}></Route>
                           <Route path='/Appointment' element={<Appointment />}></Route>
                           <Route path='/City' element={<City />}></Route>
                           <Route path='/Delet_City' element={<Delet_City />}></Route>
                           <Route path='/Patient_list' element={<Patient_list />}></Route>
                           <Route path='/Doctor_list' element={<Doctor_list />}></Route>
                           <Route path='/Doctor_Details/:id' element={<Doctor_Details />}></Route>
                           <Route path='/Pending_doctor' element={<Pending_doctor />}></Route>
                           <Route path='/Transactions' element={<Transactions/>}></Route>
                           <Route path='/*' element={<Navigate to={'/'} />}></Route>
                        </Route>

                     </Routes>
                  </div>
               </div>
            )}
         </BrowserRouter>
      </GlobalInfo.Provider>

   );
}

export default App;
