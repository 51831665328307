import React, { useState, useEffect } from 'react'
import './Delet_City.css';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

export default function Delet_City() {
    const navigat = useNavigate()

    const [allcountry, setAllcountry] = React.useState([]);
    const [allCityBy, setAllCityBy] = React.useState([]);

    const [c_id, setC_id] = React.useState("");
    const [selectedCountry, setSelectedCountry] = React.useState("");

    const [city, setCity] = React.useState("");
    const [city_id, setCity_id] = React.useState("");

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const Countries = localStorage.getItem("Countries");
        const Countries_2 = JSON.parse(Countries);

        if (Countries != null) {
            setAllcountry(Countries_2)
        }
        else { }

        Get_Countries()
    }, [])


    const Get_Countries = async () => {
        let api = Connection + "rest_apis.php?action=get_country";

        try {
            const response = await fetch(api, {
                method: "POST",
                headers: {},
            });
            const data = await response.json();

            console.log("data => ", data.response)

            if (data.response !== 'fail') {
                setAllcountry(data.response);
                localStorage.setItem("Countries", JSON.stringify(data.response));
            }
            else {
                setAllcountry([]);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
        }
    };



    const handleCountryChange = (event) => {
        const selectedCountryId = event.target.value;
        const selectedCountry = allcountry.find(country => country.id === selectedCountryId);

        console.log("Selected Country ID:", selectedCountry.id);
        console.log("Selected Country:", selectedCountry.country);

        setSelectedCountry(selectedCountryId);
        setC_id(selectedCountry.id)

        Get_Cities(selectedCountry.id)
    };



    const Get_Cities = async (country_id) => {
        let uploaddata = new FormData();

        uploaddata.append("country_id", country_id);

        let api = Connection + "rest_apis.php?action=get_city";

        try {
            const response = await fetch(api, {
                method: "POST",
                headers: {},
                body: uploaddata,
            });
            const data = await response.json();

            console.log("data => ", data.response)

            if (data.response !== 'fail') {
                setAllCityBy(data.response);
                localStorage.setItem("Countries", JSON.stringify(data.response));
            }
            else {
                setAllCityBy([]);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
        }
    };


    const open_delete_modal = (id) => {
        setModalDelete(true)
        setCity_id(id)
    }


    const Delete_citey = async (event) => {
        event.preventDefault();

        console.log("city_id => ", city_id)
        // Get_Cities(c_id)

        setIsLoading(true);

        let uploaddata = new FormData();

        uploaddata.append("city_id", city_id);

        let api = Connection + "rest_apis.php?action=delete_cittey";
        console.log("pass => ", api);

        fetch(api, {
            method: "POST",
            headers: {
            },
            body: uploaddata,
        })
            .then((response) => response.json())
            .then((response) => {

                let record = response.response;
                console.log("record => ", record)

                if (record === "fail") {
                    setModalDelete(!modalDelete)
                    setIsLoading(false);
                    alert('Something went wrong')
                }
                else {
                    setModalDelete(!modalDelete)
                    setIsLoading(false);
                    // Get_Cities(c_id)

                    setTimeout(() => {
                        toast.success('Your City successfully Delete!');
                    }, 100);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const open_edit_modal = (id, City) => {
        setModalEdit(true)

        setCity_id(id)
        setCity(City)
    }

    
    const Update_City = async () => {
        let uploaddata = new FormData();

        if (city == '') {
            alert("Please enter City.")
        }
        else {
            setIsLoading(true);

            uploaddata.append("city_id", city_id);
            uploaddata.append("city", city);

            let api = Connection + "rest_apis.php?action=Update_City";
            console.log("pass => ", api);

            fetch(api, {
                method: "POST",
                headers: {
                },
                body: uploaddata,
            })
                .then((response) => response.json())
                .then((response) => {

                    let record = response.response;

                    if (record === "success") {
                        Get_Cities(c_id)

                        setIsLoading(false)
                        setModalEdit(false)
                        toast.success('Your City successfully Update!');
                    }
                    else {
                        setIsLoading(false)
                        setModalEdit(false)
                        alert('Something went wrong')
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }


    return (
        <div className='table_review'>

            <div className='responsive_review'>
                <div className='head_card'>
                    <h2>Edit City</h2>
                </div>
                <hr></hr>


                <div className='Update_Card'>
                    <div className="update_input">
                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>Choose Country</label>
                            </div>

                            <select
                                className="update_countrry"
                                id="update_country"
                                onChange={handleCountryChange}
                                value={selectedCountry}
                            >
                                <option value="">Select Country</option>
                                {allcountry.length > 0 &&
                                    allcountry.map((country) => (
                                        <option key={country.id} value={country.id}>
                                            {country.country}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>


                        {allCityBy != '' ?
                            <div className='table_responsiv_city'>
                                <table className='table_tag'>
                                    <tr>
                                        <th>ID</th>
                                        <th>Country Id</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>


                                    {allCityBy.map((row) => (
                                        <tr>
                                            <td>{row.id}</td>
                                            <td>{row.c_id}</td>
                                            <td>{row.city}</td>
                                            <td>
                                                <span className='aution_btn'>
                                                    <button onClick={() => open_delete_modal(row.id)}>Remove City</button>
                                                    <button onClick={() => open_edit_modal(row.id, row.city)}>Update City</button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            :
                            <div style={{ textAlign: 'center', marginTop: 40 }}>
                                <p>No Record Found</p>
                            </div>
                        }

                    </div>

                </div>
            </div>


            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE CITY
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this city?</h4>
                        {isLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={isLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_citey}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>




            <Modal size='lg' isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
                <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
                    UPDATE CITY
                </ModalHeader>

                <ModalBody>

                    <form className='Form_all_patient'>
                        <div class="update_second_subject">
                            <div className='updat_width_subject'>
                                <div className='updt_subject_name'>
                                    <label>City</label>
                                </div>
                                <input type="text" class="subject" id="subject" placeholder="Address" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                        </div>

                        <div class="update_buttun">
                            {isLoading ?
                                <button class="submit" id='loader' type="reset">
                                    <PulseLoader
                                        color={"white"}
                                        loading={isLoading}
                                        //  size={5}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </button>
                                :
                                <button onClick={Update_City} class="submit" id='submite' type="submit">Update</button>
                            }
                        </div>
                    </form>

                </ModalBody>
            </Modal>


            <ToastContainer />
        </div>
    );
}