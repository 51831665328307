import React, { useState, useEffect } from 'react'
import './City.css';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function City() {
    const navigat = useNavigate()

    const [allcountry, setAllcountry] = React.useState([]);

    const [c_id, setC_id] = React.useState("");
    const [selectedCountry, setSelectedCountry] = React.useState("");
    const [city, setCity] = React.useState("");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const Countries = localStorage.getItem("Countries");
        const Countries_2 = JSON.parse(Countries);

        if (Countries != null) {
            setAllcountry(Countries_2)
        }
        else { }

        Get_Countries()
    }, [])


    const Get_Countries = async () => {
        let api = Connection + "rest_apis.php?action=get_country";

        try {
            const response = await fetch(api, {
                method: "POST",
                headers: {},
            });
            const data = await response.json();

            console.log("data => ", data.response)

            if (data.response !== 'fail') {
                setAllcountry(data.response);
                localStorage.setItem("Countries", JSON.stringify(data.response));
            }
            else {
                setAllcountry([]);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
        }
    };



    const handleCountryChange = (event) => {
        const selectedCountryId = event.target.value;
        const selectedCountry = allcountry.find(country => country.id === selectedCountryId);

        console.log("Selected Country ID:", selectedCountry.id);
        console.log("Selected Country:", selectedCountry.country);

        setSelectedCountry(selectedCountryId);
        setC_id(selectedCountry.id)
    };



    const Add_New_City = async () => {
        if (c_id == '') {
            alert("Please select Country.")
        }
        else if (city == '') {
            alert("Please enter your City.")
        }
        else {
            setIsLoading(true);

            setSelectedCountry("");
            setC_id("");
            setCity("");

            let uploaddata = new FormData();

            uploaddata.append("c_id", c_id);
            uploaddata.append("city", city);

            let api = Connection + "rest_apis.php?action=Add_New_City";
            console.log("pass => ", api);

            fetch(api, {
                method: "POST",
                body: uploaddata,
            })
                .then((response) => response.json())
                .then((response) => {

                    let record = response.response;

                    if (record === "success") {
                        setIsLoading(false)
                        toast.success('Your City successfully Add!');
                    }
                    else {
                        setIsLoading(false)
                        alert('Something went wrong')
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }



    return (
        <div className='table_review'>

            <div className='responsive_review'>
                <div className='head_card'>
                    <h2>Add City</h2>
                </div>
                <hr></hr>


                <div className='Update_Card'>
                    <div className="update_input">
                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>Choose Country</label>
                            </div>

                            <select
                                className="update_countrry"
                                id="update_country"
                                onChange={handleCountryChange}
                                value={selectedCountry}
                            >
                                <option value="">Select Country</option>
                                {allcountry.length > 0 &&
                                    allcountry.map((country) => (
                                        <option key={country.id} value={country.id}>
                                            {country.country}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>


                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>Enter City</label>
                            </div>

                            <input type="text" className="update_countrry" id="update_country" placeholder="City" required onChange={(e) => setCity(e.target.value)} value={city} />
                        </div>

                    </div>

                    <div class="Updatebuttun">
                        {isLoading ?
                            <button class="submit" id='loader' type="reset">
                                <PulseLoader
                                    color={"white"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :

                            <button onClick={Add_New_City} class="submit" id='submite' type="submit">Add City</button>
                        }
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}